// NavBar
.navbarContainer {
  height: 10vh;
  position: fixed;
  margin-top: 5vh;
  width: 90vw;
  background: linear-gradient(to bottom, #000000, #0f0f0f, #000000);
  padding: 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px solid black;
  color: #c5c5c5;
  z-index: 32;
  @media screen and (max-width: 900px) {
    margin-top: 0;
  }
  .logoContainer {
    height: 100%;
    width: 5vw;
    background-color: #0f0f0f;
    display: flex;
  }

  .menu {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
      display: none;
    }
    a {
      text-decoration: none;
      color: #c5c5c5;
      transition: scale 0.3s;
    }
    a:hover {
      scale: 0.93;
    }
    ul {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      img {
        width: 15vw;
      }
    }
  }
}

// TopBar
.topbarContainer {
  background-color: #000;
  z-index: 33;
  color: #cd9706;
  height: 5vh;
  width: 90vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  p {
    a {
      color: #cd9706;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 900px) {
    justify-content: space-between;
    z-index: 0;
  }
  .language {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 12%;
    height: 100%;
    z-index: 502;

    button {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: transparent;
      border: 1px solid transparent;
      color: #cd9706;
      font-weight: 700;
      transition: color 0.3s;
      padding: 5%;
      cursor: pointer;
      img {
        width: 20%;
      }
    }
    button:hover {
      background-color: #000;
      color: #c5c5c5;
    }
    .langList {
      height: 50%;
      width: 100%;
      button {
        width: 100%;
        border: 1px solid #cd9706;
      }
    }
  }
}

// DRAWER SLIDER
.sideBarContainer {
  display: none;
  z-index: 33;
  @media screen and (max-width: 1224px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    height: 20px;
    cursor: pointer;
    z-index: 33;
    .hBurger {
      height: 2px;
      width: 15%;
      background-color: #8c6700;
      border-radius: 2px;
    }
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      right: 0;
      margin: 0;
      padding: 0;
      overflow: hidden;
      background: linear-gradient(0.25turn, #040404, #0f0f0f, #040404);
      z-index: 33;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-weight: 700;
        color: #8c6700;
        font-size: 2em;
        height: 10vh;
        width: 90vw;
        z-index: 33;
        img {
          height: 12vh;
          margin-bottom: 10vh;
        }
        h1 {
          width: 100%;
          text-align: left;
          font-size: 1em;
          margin: 0;
          padding: 0;
          z-index: 33;
        }
        // background-image: url("../Assets/logo.png");
        // background-clip: text;
        // -webkit-background-clip: text;
        // color: transparent;
      }
      .sideBarCloseButton {
        position: absolute;
        top: 30px;
        right: 10px;
        width: 20%;
        height: 4%;
        cursor: pointer;
      }

      .sideBarCloseButton:before,
      .sideBarCloseButton:after {
        position: absolute;
        right: 40%;
        top: 5%;
        content: " ";
        height: 80%;
        width: 5px;
        border-radius: 10px;
        background-color: #8c6700;
      }
      .sideBarCloseButton:before {
        transform: rotate(45deg);
      }
      .sideBarCloseButton:after {
        transform: rotate(-45deg);
      }
    }
  }
}

body {
  background-color: black;
}

.testimonyContainer {
  height: 80%;
  width: 80%;
  border-radius: 20px;
  box-shadow: inset 0px 0px 100px black;
  overflow: hidden;
  background-color: #000000ce;
  .swiper {
    height: 100%;
    width: 100%;
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.footerContainer {
  height: 10vh;
  width: 100vw;
  display: flex;
  position: relative;
  background: linear-gradient(to bottom, #000000, #0f0f0f, #000000);
  @media screen and (max-width: 1224px) {
    flex-direction: column;
    height: 30vh;
  }
  .borderItem {
    border-bottom: 0.5px solid #8c6700;
  }
  .items {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 1224px) {
      // background-color: #8c6700;
      width: 100vw;
      overflow: hidden;
    }
    .social {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      h4 {
        color: #cd9706;
        margin: 0;
      }
      @media screen and (max-width: 1224px) {
        flex-direction: row;
        justify-content: space-evenly;
        width: 95%;
      }
      p {
        margin: 0;
        @media screen and (max-width: 1224px) {
          font-size: 1rem;
          text-align: center;
        }
      }
      img {
        width: 50%;

        @media screen and (max-width: 1224px) {
          width: 40%;
          margin: 0 auto;
        }
      }
    }
  }
}
.items:nth-child(2) {
  display: flex;
  width: 100%;
}

.collageContainer {
  height: 100vh;
  width: 100vw;
  position: relative;

  @media screen and (max-width: 1224px) {
    margin-top: -5vh;
    height: 50vh;
    z-index: 1;
  }
  .canvas {
    position: absolute;
    margin-top: 10vh;
    padding: 0% 0;
    background-color: #0f0f0f;
    overflow: hidden;
    height: 80%;
    width: 100%;
    @media screen and (max-width: 1224px) {
      height: 30%;
      width: 100%;
    }
  }
}

.formContainer {
  width: 100vw;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .formWrapper {
    width: 80%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    overflow: scroll;
    color: #c5c5c5;
    @media screen and (max-width: 1224px) {
      background-color: #0d0d0d;
      width: 100%;
      height: 30vh;
      overflow: hidden;
    }
    .formList {
      width: 100%;
      display: flex;
      justify-content: space-around;
      a {
        border-radius: 10px;
        width: 30%;
        height: 10%;
        background-color: #0f0f0f;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 20px;
        transition: scale 0.3s;
        cursor: pointer;
        color: #c5c5c5;
        border: 1px solid #cd9706;
        h1 {
          font-size: 1em;
          @media screen and (max-width: 1224px) {
            text-align: center;
          }
        }
      }
      a:hover {
        scale: 0.93;
      }
    }
  }
}

.popUpContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 23;

  img {
    @media screen and (max-width: 1224px) {
      padding: 0;
    }
    padding-top: 10vh;
    height: 80vh;
  }
}

.homeSlide {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1224px) {
    width: 100%;
  }

  .swiper {
    width: 80vw;
    height: 70%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: transparent;
    @media screen and (max-width: 1224px) {
      width: 100vw;
      // height: 70%;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @media screen and (min-width: 800px) {
      padding: 0;
    }
  }
  .swiper-pagination-bullet {
    background-color: #fff;
  }
  .swiper-pagination-bullet-active {
    background-color: #8c6700;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 70%;
    height: 100%;
  }
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    background-image: none !important;
  }
  .swiper-slide img {
    border-radius: 20px;
    @media screen and (max-width: 1224px) {
      // height: 100%;
    }
  }
}
