@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
.section0 {
  // background-position-x: 40%;
  // background-position-y: top;
  // background-repeat: no-repeat;
  // background-size: cover;
  // overflow-y: scroll;
  background-color: transparent;
  @media screen and (max-width: 1224px) {
    background-position-x: 80%;
    background-size: cover;
    background-position: bottom;
  }
  position: relative;
  background-color: #000000c8;
}
.section1 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0%;
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;
  overflow: hidden;
  p {
    width: 50%;
    height: 60vh;
    font-size: 4em;
    font-weight: 700;
    text-align: center;
    background-image: linear-gradient(
      to bottom,
      #bc8d00,
      #bc8d00,
      #bc8d00,
      #bc8d00,
      #bc8d00
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // -webkit-text-stroke: 1px #cd9806b4;
    background-color: #0d0d0db0;
    padding-top: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 100;
    border-radius: 10px;
    font-family: "Great Vibes", cursive;
    overflow: hidden;

    // font: 400 10vh;
    @media screen and (max-width: 1224px) {
      // background-color: #cd9706;
      font-size: 3.5em;
      width: 100%;
      text-align: left;
      letter-spacing: 5px;
      padding: 0 10%;
      font-weight: 400;
    }
    @media screen and (max-width: 800px) {
      font-size: 3rem;
    }
  }
}
.section2 {
  height: 100vh;
  // position: absolute;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.5%;
  @media screen and (max-width: 1224px) {
    height: 100%;
    width: 100vw;
    padding: 0;
    flex-direction: column;
    background-size: 110vh;
    justify-content: center;
  }
  .video {
    // height: 100%;
    width: 25vw;
    border-radius: 10px;
    @media screen and (max-width: 1224px) {
      width: 95%;
      border-radius: 0;
    }
  }
  .textContainer {
    // height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    line-height: 4vh;
    @media screen and (max-width: 1224px) {
      line-height: 2.3vh;
      width: 100%;
      height: 70%;
      justify-content: center;
      margin: 0;
    }
    .title {
      display: flex;
      @media screen and (max-width: 1224px) {
        padding: 0 5%;
      }
      h1 {
        font-family: "Playfair Display", serif;
        font-size: 2em;
        margin: 0;
        padding: 0 2.5%;
        font-weight: 600;
        color: #cd9706;
        @media screen and (max-width: 1224px) {
          color: #cd9706;
        }
      }
      .line {
        height: 50%;
        margin-left: 5%;
        height: 100%;
        border-radius: 20px;
        width: 4px;
        background-color: #82560c;
      }
    }
    p {
      color: #fcfcfc;
      // text-align: left;
      // background-color: #0d0d0db0;
      border-radius: 10px;
      font-size: 1em;
      width: 50vw;
      padding: 2.5%;
      @media screen and (max-width: 1224px) {
        background-color: transparent;
        width: 90%;
        padding: 5%;
        color: #c5c5c5;
      }
    }
  }
}
.section3 {
  height: 86vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-size: auto;
  background-position-x: center;
  background-repeat: no-repeat;
  background-color: #82560c;
  overflow: hidden;
  @media screen and (max-width: 1224px) {
    flex-direction: column;
    height: 90vh;
    background-size: cover !important;
    overflow: hidden;
  }

  p {
    font-size: 2em;
    @media screen and (max-width: 1224px) {
      font-size: 1.5em;
    }
  }
  .section3Wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    padding: 0 5%;
    align-items: center;

    @media screen and (max-width: 1224px) {
      width: 100%;
    }

    .writing {
      display: flex;
      flex-direction: column;
      width: 100%;
      h1 {
        color: #cd9706;
        font-family: "Playfair Display", serif;
      }
      @media screen and (max-width: 1224px) {
        h1 {
          margin: 0;
          font-family: "Playfair Display", serif;
          color: #cd9706;
          text-align: center;
        }
        height: 80%;
        padding: 5%;
        p {
          text-align: left;
        }
      }
    }
    .section3Button {
      height: 5vh;
      // width: 30%;
      border: 2px solid #cd9706;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      font-weight: 700;
      transition: background-color 0.3s;
      color: #c5c5c5;
      cursor: pointer;
      @media screen and (max-width: 1224px) {
        width: 90%;
        height: 10vh;
        color: #c5c5c5;
        font-size: 1.2em;
        text-align: center !important;
        padding: 5%;
      }
    }
    .section3Button:hover {
      background-color: #000;
    }
    p {
      font-size: 1em;
      @media screen and (max-width: 1224px) {
        // width: 80vw;
        text-align: justify;
        padding: 0;
        color: #c5c5c5;
      }
    }
    .section3Left {
      background-color: #82560c;
      width: 66%;
    }
  }
}
.section4 {
  height: 86vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // // background-color: #a2a2a2;
  background-size: 100%;
  background-position-y: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 1224px) {
    height: 90vh;
    background: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.section5 {
  height: 85vh;
  // background-size: 100% 100%;
  background-position-x: center;
  background-position-y: flex-end;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000c8;
  @media screen and (max-width: 1224px) {
    background-size: 100vh 100vw;
    background-position-y: center;
    height: 60vh;
  }

  .comingSoon {
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 1224px) {
      margin-top: -20vh;
      height: 40vh;
    }

    p {
      margin: 0;
      font-size: 5vh;
      font-family: "Playfair Display", serif;
      color: #f59b00;
      text-shadow: 0 -40px 100px, 0 0 2px, 0 0 1em #000, 0 0 0.5em #000,
        0 0 0.1em #000, 0 10px 3px #000;
      @media screen and (max-width: 1224px) {
        font-size: 2em;
      }
    }
    b {
      font-size: 3em;
      @media screen and (max-width: 1224px) {
        font-size: 1em;
      }
    }
  }
}

// GALLERY PAGE
.galleryContainer {
  background-color: #0d0d0d;
  @media screen and (max-width: 1224px) {
    width: 100%;
    // background-color: #82560c;
    overflow: hidden;
  }
  .topSection {
    height: 100vh;
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      113.46deg,
      #000000 26.31%,
      #2f2f2f8e 61.54%,
      #000000 98.39%
    );

    @media screen and (max-width: 1224px) {
      height: 85vh;
      width: 100vw;
      overflow: hidden;
    }
    .textAndImageContainer {
      width: 100vw;
      overflow-x: hidden;
      overflow-y: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      @media screen and (max-width: 1224px) {
        margin-top: 30vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
      }
      // height: 90%;
      .bannerText {
        // margin-top: -100px;
        overflow: hidden;
        padding-left: 5vw;
        width: 35vw;
        display: flex;
        flex-direction: column;
        // z-index: 1;
        h1:nth-of-type(4) {
          color: #bc8d00;
        }

        @media screen and (max-width: 1224px) {
          position: absolute;
          margin-top: 36vh;
          width: 100%;
          height: 100%;
          z-index: 2;
          font-family: "Playfair-Display", "serif";

          h1:nth-of-type(1) {
            font-size: 50px;
            margin-top: 1vh;
          }
          h1:nth-of-type(2) {
            font-size: 50px;
            margin-top: 1vh;
          }
          h1:nth-of-type(3) {
            z-index: 29;
            font-size: 50px;
            margin-top: 1vh;
          }
          h1:nth-of-type(4) {
            z-index: 31;
            // margin-top: 20vh;
            width: 100% !important;
            font-size: 50px;
          }
          h1:nth-of-type(5) {
            z-index: 31;
            font-size: 50px;
            margin-bottom: 1vh;
          }
        }

        h1 {
          font-size: 5.5em;
          line-height: 100px;
          margin: 0;
          font-family: "Playfair Display", serif;

          @media screen and (max-width: 800px) {
            line-height: 50px;
          }
        }
      }

      .imageContainer {
        // margin-top: -10vh;
        width: 50vw;
        @media screen and (max-width: 1224px) {
          position: absolute;
          overflow: hidden;
          width: 100vw;
          opacity: 1;
          z-index: 30;
        }
        img {
          height: 100vh;
          z-index: 1;
          background: cover;

          margin-left: -15vh;
          margin-top: -15vh;
          @media screen and (max-width: 1224px) {
            // height: 60vh;
            height: 100%;
            // margin-left: -15vh;
            margin-top: 40vh;
            width: 150vw;
            // scale: 1.5;
            margin-left: -10vh;
          }
        }
      }
    }
    .heroText {
      // margin-top: -15vh;
      background: linear-gradient(to bottom, #0000008a, #1818188a, #0000008a);
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1224px) {
        margin-top: 0;
        background-color: transparent;
      }

      p {
        text-align: center;
        width: 45%;
        font-size: 1.3em;
        color: #bc8d00;
        @media screen and (max-width: 1224px) {
          justify-content: flex-end;
          align-items: flex-end;
          z-index: 21;
          font-size: 0.9em;
          width: 90%;
          // background-color: #0d0d0d;
          padding: 5%;
          margin-top: 40vh;
          z-index: 31;
        }
      }
    }
  }
  .bottomSection {
    // height: 100%;
    margin: 10vh 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1224px) {
      margin: 5% 5%;
    }
  }
  .modelContainer {
    overflow: hidden;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: 10px;
    @media screen and (max-width: 1224px) {
      grid-template-columns: auto auto;
    }
    // column-gap: 10px;
    // row-gap: 10px;
    .imgContainer {
      width: 100%;
      // height: 100%;
      position: relative;
      transition: 0.3s scale;
      border-radius: 20px;
      filter: grayscale(1);
      img {
        z-index: -1;
        width: 100%;
        height: 100%;
      }
      .showDesc {
        position: absolute;
        z-index: 1;
        width: 100%;
        top: 45%;
        h1 {
          text-align: center;
          font-size: 2em;
          color: #0d0d0d;
          background-color: #ffffffb7;
          @media screen and (max-width: 1224px) {
            font-size: 2em;
          }
        }
      }
      .showMore {
        text-decoration: none;
        color: #fff;
        bottom: 0;
        position: absolute;
        justify-self: center;
        background-color: #000000b2;
        height: 10%;
        width: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
          font-size: 1em;
          font-family: "Playfair Display", serif;
        }
      }
    }
    .imgContainer:hover {
      scale: 0.98;
      filter: grayscale(0);
      cursor: pointer;
    }
  }
}

// FORM ITEM CONTAINER
.FormContainer {
  height: 100%;
  padding-top: 20vh;
  padding-bottom: 5vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, #0000008a, #1818188a, #0000008a);
  @media screen and (max-width: 1224px) {
    height: 100%;
    padding-top: 10vh;
  }
  .FormTitle {
    // width: 80vw;
    padding: 0 10vh;
    border-radius: 20px;
    color: #c5c5c5;
    @media screen and (max-width: 1224px) {
      width: 100%;
      text-align: center;
      border-radius: 0px;
      padding: 0;
    }
  }
  .formContents {
    height: 100%;
    width: 60vw;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1224px) {
      width: 100vw;
    }
    form {
      width: 80%;
      display: grid;
      grid-template-columns: auto;

      @media screen and (max-width: 1224px) {
        grid-template-columns: auto;
      }
      label {
        width: 100%;
        height: 100%;

        .imageUpload {
          width: 100%;
          input[type="file"] {
            height: 20vh;
          }
          input[type="file"]::file-selector-button {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1em;
            font-weight: 700;
            border: 0.5px solid #cd9706;
            padding: 0.2em 0.4em;
            border-radius: 10px;
            background-color: transparent;
            transition: 0.3s color;
            margin: 0;
            width: 100%;
            height: 5vh;
            color: #82560c;
            overflow: hidden;
            cursor: pointer;
          }
          input[type="file"]::file-selector-button:hover {
            color: #fff;
          }
        }
      }
      .zIndex {
        z-index: 30;
      }

      p {
        font-weight: 700;
      }
      .sex {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 0.5px solid #cd9706;
        background: linear-gradient(to bottom, #000, #181818, #000);
        border-radius: 10px;
        @media screen and (max-width: 1224px) {
          flex-direction: column;
          input {
            width: 10vw;
          }
          p {
            width: 50%;
          }
        }
        .radioContainer {
          padding-left: 20px;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          cursor: pointer;
          @media screen and (max-width: 1224px) {
            width: 100%;
            padding: 0;
          }
        }
      }
      .imageUploadContainer {
        background-color: #000;
        height: 20vh;
      }

      input {
        height: 5vh;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #cd9706;
        background: linear-gradient(to bottom, #000, #181818, #000);
        color: #c5c5c5;
        text-indent: 10px;
      }

      input[type="submit"] {
        margin-top: 10%;
        background: #cd9706;
        color: #1d1d1d;
        cursor: pointer;
        transition: scale 0.3s;
      }
      input[type="submit"]:hover {
        scale: 0.95;
      }
      input[type="date"]:after {
        content: "\25BC";
        color: #555;
        padding: 0 5px;
      }

      /* change color of symbol on hover */
      input[type="date"]:hover:after {
        color: #bf1400;
      }

      /* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
      input[type="date"]::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        color: transparent;
        background: transparent;
      }
      .subInput {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 5%;
        input {
          display: flex;
          // width: 20vw;
          margin-top: 10px;
        }
      }
    }
  }
}

.FormContainer2 {
  height: 100%;
  padding-top: 25vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  @media screen and (max-width: 1224px) {
    padding-top: 10vh;
    overflow: hidden;
    height: 100%;
  }
  .FormTitle {
    // width: 80vw;
    padding: 0 20vh;
    background: linear-gradient(to bottom, #000, #181818, #000);
    color: #c5c5c5;
    @media screen and (max-width: 1224px) {
      padding: 0 5vh 5vh 5vh;
    }
  }
  .formContents {
    height: 100%;
    width: 90vw;
    display: flex;
    padding-bottom: 10vh;
    justify-content: center;

    form {
      width: 80%;
      display: flex;
      flex-direction: column;
      column-gap: 5%;
      label {
        width: 100%;
        -webkit-file-upload-button {
          // display: flex;
          // color: #000000;
        }
      }

      input {
        height: 5vh;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #cd9706;
        background: linear-gradient(to bottom, #000, #181818, #000);
        color: #c5c5c5;
        text-indent: 10px;
      }

      input[type="submit"] {
        margin-top: 15%;
        background: #cd9706;
        color: #1d1d1d;
        transition: scale 0.3s;
        cursor: pointer;
      }
      input[type="submit"]:hover {
        scale: 0.95;
      }
    }
  }
}

// Profile
.profileContainer {
  height: 100%;
  width: 80vw;
  padding: 15% 10% 0% 10%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #0d0d0d;
  @media screen and (max-width: 1224px) {
    padding: 30% 10% 0% 10%;
  }
  .backBtn {
    position: fixed;
    height: 2vw;
    width: 5vw;
    background: linear-gradient(to right, #000000, #0d0d0d, #000000);
    border-radius: 5%;
    border: 2px solid #82560c;
    z-index: 21;
    top: 20vh;
    right: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.2s scale;
    cursor: pointer;
    @media screen and (max-width: 1224px) {
      height: 8vw;
      width: 15vw;
      top: 3vh;
      left: 2vh;
      z-index: 23;
      background-color: transparent;
    }
    .arrow {
      border: solid #fff;
      width: 1vh;
      height: 1vh;

      border-width: 0 2px 2px 0;
      display: inline-block;
      // height: 10%;
      // padding: 3px;
      margin-left: 0.5vh;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }
  .backBtn:hover {
    scale: 0.9;
  }
  .profileTop {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 20;

    @media screen and (max-width: 1224px) {
      flex-direction: column;
    }
    .imgContainer {
      height: 50vh;
      @media screen and (max-width: 1224px) {
        height: 50vh;
      }
      img {
        height: 100%;
        border-radius: 20px;
      }
    }
    .textContainer {
      width: 70%;
      // background-color: #a17a06;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      h1 {
        font-size: 1.2em;
        font-family: "Playfair Display", serif;
      }
      @media screen and (max-width: 1224px) {
        width: 100%;
        height: 100%;
        h1 {
          font-size: 1em;
        }
      }
    }
  }
  .profileBody {
    // height: 100%;
    margin: 20px 0 0 0;
    width: 100%;
    display: grid;
    padding: 20px;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    row-gap: 20px;
    border-radius: 20px;
    z-index: 1;
    @media screen and (max-width: 1224px) {
      grid-template-columns: auto auto;
    }

    img {
      width: 100%;
      border-radius: 20px;
      cursor: pointer;
    }
  }

  .footerContainer {
    bottom: 0;
    position: relative;
  }
}
.profileImageClicked {
  filter: blur(5px);
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.wave {
  background: #a17704;
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 50vh;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
    transform: translateX(1);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1);
  }
}

.popUpContainer {
  height: 100%;
  width: 100%;
  background-color: #000000cc;
  position: fixed;
  z-index: 23;
  overflow: hidden;
  .popUp {
    height: 40vh;
    width: 40vw;
    background: linear-gradient(to bottom, #000000, #131313, #000000);
    border: 0.5px solid #cd9706;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 1224px) {
      width: 90vw;
    }
    h1 {
      font-family: "Playfair Display", serif;
      @media screen and (max-width: 1224px) {
        font-size: 2em;
        width: 80vw;
      }
    }
    h3 {
      cursor: pointer;
      transition: 0.3s scale;
      @media screen and (max-width: 1224px) {
        font-size: 2em;
      }
    }
    h3:hover {
      scale: 1.1;
      color: #cd9706;
    }
  }
}
.hidePopUp {
  display: none;
}
.minorContainer {
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 30;
}
.Toastify__progress-bar {
  background-color: #ffcc00 !important;
}
