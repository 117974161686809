@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Quicksand:wght@700&display=swap");
body {
  margin: 0;
  font-family: "Quicksand", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
