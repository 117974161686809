.MainContainer {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  color: #ffffff;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
